<template>
  <div>
    <div class="d-flex flex-wrap justify-center">

      <v-card
        v-for="service in services"
        :key="service._id"
        max-width="344"
        class="my-4 mx-4 d-flex flex-column align-content-start"
        :class="checkedServices.includes(service) ? 'teal--text' : ''"
        :color="checkedServices.includes(service) ? '#dafdfa' : ''"
        outlined
        elevation="3"
        @click="clickHandler(service)"
        rounded="xl"
      >
        <ImageForCardWithTitle
          :image="service.image"
          :image-url="service.imageUrl"
          :title="service.title"
          main-theme-color="teal"
        />

        <v-card-subtitle
          :class="checkedServices.includes(service) ? 'teal--text' : ''"
          class="flex-grow-1">
          {{ service.subtitle }}
        </v-card-subtitle>
        <v-card-subtitle
          :class="checkedServices.includes(service) ? 'teal--text' : ''"
        >
          <span class="font-weight-bold">Цена: {{ service.price }} руб</span>
        </v-card-subtitle>
      </v-card>

    </div>

    <div class="d-flex flex-column align-center">
      <div v-if="computedTotal() > 0 && computedTotal() < (category.minTotal || 1)"
           class="error--text my-5 mx-5">
        Вы выбрали процедуры на сумму: <b>{{ computedTotal() }} руб</b>.
      </div>

      <v-divider class="mx-4 my-4"></v-divider>

      <div v-if="computedTotal() >= (category.minTotal || 1)"
           class="my-5 mx-5 teal--text"
      >
        Предварительный расчет стоимости услуги: <b>{{ `${computedTotal()} рублей` }}</b>
      </div>

      <div v-else-if="checkedServices.length"
           class="my-5 mx-5"
      >
        Для записи на услугу "{{ category.title.toUpperCase() }}"
        необходимо выбрать процедуры минимум на <b>{{ category.minTotal || 1 }} руб</b>.
        <br><br>
        Если Вам необходима только выбранная процедура, Вы можете связаться с мастером по телефону
        <v-btn fab small elevation="3" href="tel:+79042280684" style="text-decoration: none">
          <v-icon color="teal">mdi-phone</v-icon>
        </v-btn>
      </div>

      <v-btn
        @click.prevent="submitHandler"
        elevation="3"
        color="teal"
        max-width="344"
        class="mx-4 mb-10"
        :disabled="!(computedTotal() >= (category.minTotal || 1))"
        :dark="computedTotal() >= (category.minTotal || 1)"
      >
        Продолжить
      </v-btn>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import ImageForCardWithTitle from "@/components/ImageForCardWithTitle";

export default {
  name: "ChooseServiceMaster",
  components: {ImageForCardWithTitle},
  data: () => ({
    services: [],
    checkedServices: [],
  }),
  computed: {
    ...mapGetters({
      ownerId: "ownerId",
      category: "category",
    })
  },
  methods: {
    async loadServiceList() {
      const id = this.ownerId
      const category = this.category._id

      const response = await fetch(`${this.$store.state.server}/service/load-service-list-for-category`, {
        method: "POST",
        headers: {
          "content-type": "application/json"
        },
        body: JSON.stringify({category, id})
      })

      const json = await response.json()
      if (json.message) {
        this.$store.commit("setServerMessage", json.message)
      }
      this.services = json.services
    },

    clickHandler(service) {
      if (this.checkedServices.includes(service))
        this.checkedServices = this.checkedServices.filter(checkedService => {
          return checkedService !== service
        })
      else this.checkedServices.push(service)
    },

    computedTotal() {
      return this.checkedServices.reduce((total, service) => {
        return total + parseInt(service.price)
      }, 0)
    },

    submitHandler() {
      if (!this.checkedServices.length) {
        return
      }
      this.$store.commit("setServices", this.checkedServices)
      this.$store.commit("setTotal", this.computedTotal())
      this.$router.push("/introduce-master")
    }
  },

  async mounted() {
    if (
      !this.$store.getters.category
      || !this.$store.getters.date
      || !this.$store.getters.time
    ) {
      await this.$router.push("/calendar")
      return
    }
    await this.loadServiceList()
  }
}
</script>

<style scoped>

</style>
